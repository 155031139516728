


import "./Styles/Footer.scss";

function Footer()
{
     


    return(<div className="footerBod">

        <div className="footerBGImg">

        <h4>Darron Michael Gordon</h4>
        <h5>Darron</h5>

        </div>
    </div>

    );
}

export default Footer;