
import "./Styles/contact.scss";
import gameCv from "./CV's/cv.pdf";

 function Contact()
 {
     return (
        <div className="ContactBod">

        <div className="contactDetails showOnScroll">

        <div className="details">

        <h3 className="emailTitle">Email me:</h3>

        <h4 className="email">DarronMGordon@gmail.com</h4>

        <h3 className="contactCV">CV</h3>

        <h4 className="gameDevCV"><a href={gameCv} download>*CV*</a></h4>

        </div>

        </div>

        </div>
     )
 }

 export default Contact;