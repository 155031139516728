import "./Styles/GameDevProjectsStyles.scss";

import gameCv from "./CV's/cv.pdf";

function GameDevProjects(){

    function CreatePreviewBox(props)
    {
        var classNames ="previewBox "+ props.name;
        return(

                <a href={props.url}>
                    <div className={classNames}>
                        <h3>{props.name}</h3>
                    </div>
                </a>
        );
    }

    return(
        <div className="gameDevProjectsBod">
        <div className="gameDevProjectHeader showOnScroll">

        </div>
        <h1 className="showOnScroll">Game Development</h1>
        <br></br>

        <h3 className="cv showOnScroll"><a href={gameCv}>CV</a></h3>
        <br></br>

        <h5 className="gameDevIntro showOnScroll">
        My Game Development skills are self-taught, I have a passion for game development.<br/>
        I use unity and have spent the last couple years learning and gaining skills in the<br/>
         game development field my passion lies with codeing but as an idie I have learned<br/>
        a few tricks and skills to help myself along the way<br/> Here is a short quick list of some of the skills I have.
        <br/>

            C#|MonoBehaviour|NetworkBehaviour|Mirror|NetCode.<br/>
            Scriptable-Objects.
            URP & SRP, Shaders and shader Graph and unity particle effects.<br/>
            Some design patterns include:<br/>
            Singleton, Observer, StateMachine, Object-Pooling<br/>
            UI-Toolkit,
            New and old Input System, Nav-Mesh and alot of unity built in components +-30ish;
            Unity Animations and Unity Timeline, IK-Animation and rigging.
        </h5>

        <br></br>
        <a href="https://learn.unity.com/u/5e5534e1edbc2a001fca1b0c?tab=profile"><h5>Link to Unity Learn Portfolio</h5></a>
        <br></br>
      
        <h3 className="webglHeader showOnScroll">Web-GL|Game-Jams</h3>
        <div className="section1 showOnScroll">

        <CreatePreviewBox name="Color" url="https://gigglytwigg.itch.io/colour"/>
        <CreatePreviewBox name="Franko" url="https://gigglytwigg.itch.io/frankos-hat"/>
        <CreatePreviewBox name="LifeOf" url="https://gigglytwigg.itch.io/lifeof"/>
        <CreatePreviewBox name="SpaceRace" url="https://gigglytwigg.itch.io/spacerace"/>
        <CreatePreviewBox name="RunFromAnxi" url="https://gigglytwigg.itch.io/"/>
        <CreatePreviewBox name="Prep2Die" url="https://icosaplexgames.itch.io/prepare-2-die"/>

        </div>

        <h3 className="mobileHeader showOnScroll">Mobile|Hyper casual Games.</h3>
        <div className="section2 showOnScroll">
        <CreatePreviewBox name="BobbleBounce" url="https://play.google.com/store/apps/details?id=com.DarronGordon.BobbleBounce"/>
        <CreatePreviewBox name="Dodger" url="https://play.google.com/store/apps/details?id=com.GigglyTwigg.Dodger"/>
        <CreatePreviewBox name="HungryZombie" url="https://play.google.com/store/apps/details?id=com.GigglyTwigg.Catcher"/>
        </div>
        <p>who needs sleep, when you can build games.<br/> plus the game jam ends in a few hours.</p>
        </div>
    );
}

export default GameDevProjects;