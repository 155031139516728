
import homeHeaderImage from "../Images/HeadingBanner.png"

import WebDev from '../WebDev';
import "../Styles/HomeStyles.scss";
import teddyImg from "../Images/teddyImg.png";
import HomeWebDev from "./HomeWebDev";
import Contact from "../Contact";
function Home() 
{

    return(
    <div className="homeBod">
    
    <header>
         <img className="headerBannerImage" src={homeHeaderImage}></img>
    </header>

    <h1 className="PageHeader">Welcome! <br></br> To my portfolio! </h1>

    <div className="Intro">

    <img src={teddyImg} className="teddyImg showOnScroll"/>

    <div className="Introduction-Summury showOnScroll">
        <h2  className="showOnScroll">Darron Michael Gordon.</h2>

        <h6  className="showOnScroll">BacNet BAC Technician</h6>
        <h6  className="showOnScroll">Web Developer</h6>
        <h6  className="showOnScroll">Game Designer</h6>
    </div>
    <div className="Introduction showOnScroll">
        <h6> 
            I started working in the HVAC and building automation controls industry where I found my love for code working with the GCL+ Language, I have gaind a very analytical outlook due to my fault finding and debugging experience.
            while working I found myself wanting to gain more knowlage, finding books on code like, "Clean code by Robert C. Martin" and online courses was great but I needed to apply and practice my skills
            after a few python and Web Dev bootcamps I started freelanceing my web development skills, all while working on unity and building games and assoiated skills.
            I have a desire to become a great developer and give people awesome experiences through apps I have helped build.
           </h6> 
           </div>
    </div>
        <HomeWebDev/>

        <WebDev/>

        <Contact/>
    </div>
    );
}

export default Home;